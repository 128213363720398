<template>
  <video autoplay muted loop id="video-bg">
    <source
      src="https://simplevideos.fra1.digitaloceanspaces.com/Comp_1.mp4"
      type="video/mp4"
    />
  </video>
  <div class="content">
    <div class="container">
      <div class="dishes-box">
        <div v-if="permanentDishes.length" class="day-title font-heading-pro">
          <div class="day-name">Menu</div>
        </div>

        <div v-if="permanentDishes.length" class="menu">
          <div
            v-for="dish in permanentDishes"
            :key="dish.id"
            class="dishes-item dishes-permanent flex justify-space-between"
          >
            <div class="info">
              <span
                v-if="dish.attributes.media?.url || dish.attributes.media?.length"
                class="thumbnail"
              >
                <img :src="url + dish?.attributes.media[0]?.formats?.medium.url" alt="" />
              </span>
              <div>
                <div class="title">
                  {{ dish.attributes.title }}
                  <span class="new_dish" v-if="dish.new_dish">
                    <img src="../assets/images/new.gif" alt="New dish" />
                  </span>
                </div>
                <div class="small-title">
                  {{ dish.attributes.title_ru }}
                  <span v-if="dish.attributes.title_en?.length">/</span>
                  {{ dish.attributes.title_en }}
                </div>
              </div>
            </div>
            <div class="price">{{ dish.attributes.price }}€</div>
          </div>
        </div>

        <div class="day-title font-heading-pro">
          <!-- <div class="day-name">{{ todayEt }} / {{ today }}</div> -->
          <div class="day-name">Day offer / Päeva pakkumine</div>
          <div class="day-num">{{ todayNumber }}</div>
        </div>

        <div class="menu">
          <div
            v-for="dish in dishes"
            :key="dish.id"
            class="dishes-item flex justify-space-between"
          >
            <div class="info">
              <div class="new_dish" v-if="dish.attributes.new_dish">
                <img src="../assets/images/new.gif" alt="New dish" />
              </div>
              <div class="promo" v-if="dish.attributes.promotion">
                <img src="../assets/images/promo.gif" alt="promo" />
              </div>
              <div>
                <div class="title">{{ dish.attributes.title }}</div>
                <div class="small-title">
                  {{ dish.attributes.title_ru }}
                  <span v-if="dish.attributes.title_en?.length">/</span>
                  {{ dish.attributes.title_en }}
                </div>
              </div>
            </div>
            <div class="price">{{ dish.attributes.price }}€</div>
          </div>
        </div>
        
        <div class="garnish-box" v-show="garnishes.length">
          <div class="garnish-title font-heading-pro">Garniirid / Гарниры:</div>
          <div class="garnish-item">
            <div v-for="garnish in garnishes" :key="garnish.id">
              {{ garnish.attributes.title }} / {{ garnish.attributes.title_ru }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Main",
  data: () => ({
    // url: "https://api.simpleresto.ee",
    // url: "http://localhost:1337",
    url: "https://dolphin-app-gv5vo.ondigitalocean.app/api",
    dishes: [],
    permanentDishes: [],
    garnishes: [],
    categoryDays: [],
    activeBar: false,
    valueDays: [],
    actionStatus: true,

    dayCurrent: [
      "Воскресенье",
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
    ],
    dayCurrentEt: [
      "Pühapäev",
      "Esmaspäev",
      "Teisipäev",
      "Kolmapäev",
      "Neljapäev",
      "Reede",
      "Laupäev",
    ],
    dayCurrentEn: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    today: "",
    todayEt: "",
    todayEn: "",
    todayNumber: 0,
  }),

  mounted() {
    this.getDishes();
    this.getGarnishes();
    let dayTitle = String(new Date().getDay());
    this.today = this.today.replace(this.today, this.dayCurrent[dayTitle]);
    this.todayEt = this.todayEt.replace(
      this.todayEt,
      this.dayCurrentEt[dayTitle]
    );
    this.todayEn = this.todayEn.replace(
      this.todayEn,
      this.dayCurrentEn[dayTitle]
    );
    let getMonth = new Date().getMonth();
    this.todayNumber = `${new Date().getDate()}.${getMonth < 9 ? 0 : ""}${
      getMonth + 1
    }`;
    // this.changeLogo();
  },

  methods: {
    // changeLogo() {
    //   const imageContainer = document.getElementById("logo-container");
    //   const images = imageContainer.getElementsByTagName("div");
    //   const imageCount = images.length;
    //   let currentIndex = 0;

    //   function showNextImage() {
    //     images[currentIndex].classList.remove("active");
    //     currentIndex = (currentIndex + 1) % imageCount;
    //     images[currentIndex].classList.add("active");
    //   }

    //   setInterval(showNextImage, 8000);
    // },

    getDishes() {
      this.axios
        .get(`${this.url}/dishes?pagination%5Blimit%5D=1000&populate=%2A`)
        .then((res) => {
          this.dishes = res.data.data;

          this.permanentDishes = res.data.data.filter(
            (item) => !!item.attributes.permanent_dish
          );

          this.dishes = this.dishes.filter((item) => {
            if (item.attributes.permanent_dish) {
              return false;
            }

            item.attributes.days = item.attributes.days.data.filter((d) => d.attributes.title === this.today);
            return item.attributes.days.length;
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getGarnishes() {
      this.axios
        .get(`${this.url}/garnishes?pagination%5Blimit%5D=1000&populate=%2A`)
        .then((res) => {
          this.garnishes = res.data.data;
          this.garnishes = this.garnishes.filter((item) => {
            item.attributes.days = item.attributes.days.data.filter(
              (d) => d.attributes.title === this.today
            );
            return item.attributes.days.length;
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    dishesNoPromotion() {
      return this.dishes.filter((dish) => !dish.attributes.promotion);
    },
    dishesPromotion() {
      return this.dishes.filter((dish) => dish.attributes.promotion);
    },
    dishesPermanent() {
      return this.dishes.filter((dish) => dish.attributes.permanent_dish);
    },
  },
};
</script>

<style lang="scss" scoped>
/*------------------------Dishes------------------------*/
$dark: #19191b;

.font-heading-pro {
  font-family: "Heading Pro";
}

#video-bg {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 2;
  margin-top: 394px;
}

.title-menu {
  font-size: 144px;
  line-height: 156px;
  text-align: center;
  letter-spacing: 0.15em;
  font-weight: 600;
}

.title-menu-small {
  text-align: center;
  font-size: 72px;
  line-height: 81px;
  margin-top: 8px;
  letter-spacing: 0.15em;
}

.day-title {
  display: flex;
  justify-content: space-between;
  margin-top: 96px;
  font-size: 72px;
}

.menu {
  border-top: 1px solid #fff;
  margin: 24px 0 48px;
  padding: 24px 0;
}

.dishes-box {
  padding: 15px 0;

  .dishes-item {
    position: relative;
    margin: 60px 0;
    gap: 60px;

    .info {
      position: relative;
      background: inherit;
      padding-right: 15px;
      z-index: 1;
      display: flex;
      gap: 16px;

      .promo {
        width: 60px;
        flex-shrink: 0;
        position: absolute;
        left: -70px;
        top: 10px;

        img {
          width: 100%;
        }
      }

      .thumbnail {
        flex-shrink: 0;
        position: relative;
        width: 270px;
        height: 270px;
        overflow: hidden;
        margin-right: 48px;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          height: auto;
          transform: translate(-50%, -50%);
        }
      }

      .new_dish {
        width: 119px;
        flex-shrink: 0;
        position: absolute;
        left: -102px;
        top: -20px;

        img {
          width: 100%;
        }
      }
    }

    &.dishes-permanent {
      .new_dish {
        left: -15px;
        top: -16px;
      }
    }

    .title,
    .price {
      font-size: 72px;
      line-height: 80px;
      font-weight: 600;
    }

    .small-title {
      line-height: 53px;
      font-size: 48px;
    }
  }
}

/*------------------------Garnish------------------------*/

.garnish-box {
  text-align: center;

  .garnish-title {
    font-size: 72px;
    line-height: 81px;
  }
  .garnish-item {
    margin: 15px 0;
    font-size: 48px;
  }
}
@media (min-width: 2140px) {
  #video-bg {
    width: 2160px;
  }
  .content {
    margin-top: 690px;
  }
}
</style>
