import {
    createRouter,
    createWebHistory
} from 'vue-router'
import Main from "../pages/Main";

const routes = [{
        path: '/',
        name: 'Main',
        component: Main
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('../pages/Admin')
    },
    {
        path: '/menu',
        name: 'Menu',
        component: () => import('../pages/Menu')
    },
    {
        path: '/dayoffer',
        name: 'DayOffer',
        component: () => import('../pages/DayOffer')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router