import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
    // You can set your default options here
};

const app = createApp(App)
app.use(router)
app.mount('#app')
app.use(VueAxios, axios)
app.use(Toast, options);
